import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import compact from 'lodash/compact';
import startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import flattenDeep from 'lodash/flattenDeep';

import SEO from '../../components/seo';
import TemplateLayout from '../layout';
import InfoCard from '../../components/info-card';
import Breadcrumb from '../../components/breadcrumb';
import TagsSidebar from '../../components/tags-sidebar';

import styles from './index.module.scss';

const TagPage = ({ pageContext }) => {
  const { windowWidth } = useSelector(({ global }) => global);
  const isMobile = windowWidth < 630;

  const tag = get(pageContext, 'tag', '');
  const nodes = map(get(pageContext, 'data.allPages.edges', []), 'node');

  const nodesSortedByYears = sortBy(nodes, 'year');
  const tags = uniq(flattenDeep(compact(map(nodes, '_meta.tags')))).sort().filter(t => t !== tag);

  return (
    <TemplateLayout>
      <SEO title={`Datayears | Tag | ${startCase(tag)}`} description={`all posts on "${tag}"`} />  

      <Breadcrumb
        items={[
          { name: 'Home', linkTo: '/' },
          { name: 'Tags', linkTo: '/tags' },
          { name: startCase(tag) },
        ]}
      />

      <div className={styles.cardsContainer}>
          {nodesSortedByYears.map((node, i) => (
          <div key={`${node.year}-${i}`}>
              <InfoCard 
                  key={get(node, '_meta.uid')}
                  node={node}
                  minimal={isMobile}
              />
          </div>
          ))}
      </div>

      <div styles={{marginTop:30}}>
        <TagsSidebar tags={tags} />
      </div>
    </TemplateLayout>
  );
};

TagPage.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
};

export default TagPage;
